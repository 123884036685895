import { useState, useEffect, useRef } from "react";
import '../../../../scss/Fields.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faCheckSquare, faCheck } from "@fortawesome/pro-regular-svg-icons";
import InfoPopup from "../../../popups/InfoPopup.jsx";
import {handleInitialPriceOnFieldDataChange} from "../../../functions/price-functions.jsx";

export default function CardField(props) {
    const {
        item,
        option,
        index,
        fieldsData,
        setFieldsData,
        setPriceFromFields,
        priceFromFields,
        type,
        extraPriceRules,
        setPriceUpdating,
        setExtraFilledPrice,
        priceData,
        selectedConfigurator
    } = props;

    const [activeInfoPopup, setActiveInfoPopup] = useState(false);
    const inputRef = useRef(null);
    const infoPopupRef = useRef(null);

    useEffect(() => {
        // Index 0 means that we are on the first step, in this useEffect we will change the initial price after the fieldsData is set.
        // This makes sure that the initial price is updated when the user chooses ritsscreen or solar screen, since they come from a different table
        if (index === 0 && selectedConfigurator === "zonnescherm") {
            setPriceUpdating(true);
            handleInitialPriceOnFieldDataChange(priceFromFields, setPriceFromFields, fieldsData, priceData, selectedConfigurator);
            setTimeout(() => {
                setPriceUpdating(false);
            }, 1000);
        }
    }, [fieldsData])

    const handleInputInteraction = (type, option) => {
        const inputElement = inputRef.current;
        let triggerUpdate = false;

        if (typeof priceFromFields[item.label.toLowerCase()] !== "undefined" && priceFromFields[item.label.toLowerCase()] !== parseInt(inputElement.getAttribute('data-price'))) {
            setPriceUpdating(true);
            triggerUpdate = true;
        }

        if (inputElement && type === "cards" && !inputElement.disabled) {
            const updatedFieldsData = { ...fieldsData };
            const updatedPriceFromFields = { ...priceFromFields };
            const { name, value } = inputElement;

            updatedFieldsData[name] = value;

            if(!!updatedFieldsData['type']['typeKey']) {
                delete updatedFieldsData['bediening-motor'];
                delete updatedFieldsData['bediening-zender'];

                delete updatedFieldsData['kleuren'];
                delete updatedFieldsData['kleuren-omkasting-keuze'];

                if(!!updatedPriceFromFields['bediening-motor']) {
                    delete updatedPriceFromFields['bediening-motor'];
                }

                if(!!updatedPriceFromFields['bediening-zender']) {
                    delete updatedPriceFromFields['bediening-zender'];
                }

                if(!!updatedPriceFromFields['kleuren-omkasting-keuze']) {
                    delete  updatedPriceFromFields['kleuren-omkasting-keuze'];
                }
            }

            if(inputElement.getAttribute('name') === 'uitvoering') {
                updatedFieldsData[name] = {
                    'typeVal': updatedFieldsData[name],
                    'typeKey': option['choice_key']
                }
            }

            setPriceFromFields({
                ...updatedPriceFromFields,
                [item.label.toLowerCase()]: !!option.price ? parseFloat(option.price) : parseFloat("0"),
            });

            setFieldsData(updatedFieldsData);

            if (triggerUpdate) {
                setTimeout(() => {
                    setPriceUpdating(false);
                }, 1000);
            }
        }
    };

    const handleInfoPopupClick = (e) => {
        e.stopPropagation();
        setActiveInfoPopup(!activeInfoPopup);
    }

    // Handle checked status
    let itemValue = fieldsData[item.label.toLowerCase()];
    if (typeof itemValue === 'object' && itemValue !== null && 'typeKey' in itemValue && 'typeValue' in itemValue) {
        itemValue = itemValue.typeKey || itemValue.typeVal;
    }

    return (
        <>
            <div className={`card ${fieldsData[item.label.toLowerCase()] === option.label ? 'selected' : ''}`}>

                <input
                    ref={inputRef}
                    id={option.label}
                    name={item.label.toLowerCase()}
                    type="radio"
                    value={option.label}
                    onChange={() => handleInputInteraction(type, option)}
                    checked={(fieldsData[item.label.toLowerCase()] === option.label || !!itemValue && itemValue.typeKey === option['choice_key'])}
                    data-price={!!option.price ? option.price : "0"}
                    data-cKey={!!option['choice_key'] ? option['choice_key'] : ""}
                    style={{display: 'none'}}
                />
                <label htmlFor={option.label}>
                    <div className="card-image">
                        <img src={option.image.sizes.medium_large} alt={option.label} />
                    </div>
                    <div className="card-content">
                        <div className="card-header">
                            <input
                                type="radio"
                                onChange={() => handleInputInteraction(type, option)}
                                checked={(fieldsData[item.label.toLowerCase()] === option.label || !!itemValue && itemValue.typeKey === option['choice_key'])}
                            />
                            <span>{option.label}</span>
                            {option.most_chosen && (
                                <span className="most-chosen">{option.most_chosen_label}</span>
                            )}
                        </div>
                        <ul className="card-features">
                            {option.usps.map((usp, idx) => (
                                <li key={idx}>
                                    <FontAwesomeIcon icon={faCheck} color={"#12A66C"}/>
                                    <span>{usp.usp}</span>
                                </li>
                            ))}
                        </ul>
                        {!!option['sub_label'] && (
                            <div className="card-sub-label">
                                {option['sub_label']}
                            </div>
                        )}
                    </div>
                </label>
                {option.description && (
                    <div ref={infoPopupRef} onClick={(e) => handleInfoPopupClick(e)} className="info-popup">
                        <FontAwesomeIcon icon={faCircleInfo} size="1x" style={{color: 'inherit'}}/>
                    </div>
                )}
                {!!activeInfoPopup && (
                    <InfoPopup
                        trigger={activeInfoPopup}
                        text={option.description}
                        set={setActiveInfoPopup}
                        setTextParse={true}
                    />
                )}
            </div>
        </>
    );
}
