import React, { useContext, useState } from "react";
export const GlobalContext = React.createContext([{}, () => {}]);
export const GlobalProvider = ({ children }) => {
    const [state, setState] = useState({
        configuratorData: {},
        selectedConfigurator: null,
        priceData: 0,
        colorData: null,
        engineData: null,
        priceFromFields: {},
        conditionalLogic: {},
        appliedConditionalLogic: {},
        activeField: 0,
        fieldsData: {},
        quotationFolder: {},
        headerData: {},
        allConfigurators: {},
        editProduct: "",
        completeQuotationFolder: false,
        completeQuotation: false,
        startContentData: {},
        priceUpdating: false,
        configuratorHubspotUrl: null,
        selectedColors: {},
        extraPriceRules: {},
        employeeConfigurator: false,
    });

    const setConfiguratorData = (data) => {
        setState((prevState) => ({
            ...prevState,
            configuratorData: data,
        }));
    };

    const setSelectedConfigurator = (newSelectedConfigurator) => {
        setState((prevState) => ({
            ...prevState,
            selectedConfigurator: newSelectedConfigurator,
        }));
    };

    const setPriceData = (price) => {
        setState((prevState) => ({
            ...prevState,
            priceData: price,
        }));
    };

    const setColorData = (colorData) => {
        setState((prevState) => ({
            ...prevState,
            colorData: colorData,
        }));
    };

    const setEngineData = (newEngineData) => {
        setState((prevState) => ({
            ...prevState,
            engineData: newEngineData,
        }));
    };

    const setPriceFromFields = (newPriceField) => {
        setState((prevState) => ({
            ...prevState,
            priceFromFields: newPriceField,
        }));
    };

    const setConditionalLogic = (newLogic) => {
        setState((prevState) => ({
            ...prevState,
            conditionalLogic: newLogic,
        }));
    };
    const setAppliedConditionalLogic = (newAppliedLogic) => {
        setState((prevState) => ({
            ...prevState,
            appliedConditionalLogic: newAppliedLogic,
        }));
    };

    const setActiveField = (newActiveField) => {
        setState((prevState) => ({
            ...prevState,
            activeField: newActiveField,
        }));
    }

    const setFieldsData = (newFieldsData) => {
        setState((prevState) => ({
            ...prevState,
            fieldsData: newFieldsData,
        }));
    }

    const setQuotationFolder = (newQuotation) => {
        setState((prevState) => ({
            ...prevState,
            quotationFolder: newQuotation,
        }));
    }

    const setHeaderData = (newHeaderData) => {
        setState((prevState) => ({
            ...prevState,
            headerData: newHeaderData,
        }));
    }

    const setAllConfigurators = (newAllConfData) => {
        setState((prevState) => ({
            ...prevState,
            allConfigurators: newAllConfData,
        }));
    }

    const setEditedProduct = (newEditProduct) => {
        setState((prevState) => ({
            ...prevState,
            editProduct: newEditProduct,
        }));
    }

    const setStatusQuotationFolder = (newStatus) => {
        setState((prevState) => ({
            ...prevState,
            completeQuotationFolder: newStatus,
        }));
    }

    const setCompleteQuotation = (newStatus) => {
        setState((prevState) => ({
            ...prevState,
            completeQuotation: newStatus,
        }));
    }

    const setStartContentData = (newStartContentData) => {
        setState((prevState) => ({
            ...prevState,
            startContentData: newStartContentData,
        }));
    }

    const setPriceUpdating = (newPriceStatus) => {
        setState((prevState) => ({
            ...prevState,
            priceUpdating: newPriceStatus,
        }));
    }

    const setConfiguratorHubspotUrl = (newHubspotUrl) => {
        setState((prevState) => ({
            ...prevState,
            configuratorHubspotUrl: newHubspotUrl,
        }));
    }

    const setSelectedColors = (newSelectedColor) => {
        setState((prevState) => ({
            ...prevState,
            selectedColors: newSelectedColor,
        }));
    }

    const setExtraPriceRules = (newExtraPriceRules) => {
        setState((prevState) => ({
            ...prevState,
            extraPriceRules: newExtraPriceRules,
        }));
    }
    const setEmployeeConfigurator = (newEmployeeConfigurator) => {
        setState((prevState) => ({
            ...prevState,
            employeeConfigurator: newEmployeeConfigurator,
        }));
    }

    return (
        <GlobalContext.Provider
            value={[
                state,
                {
                    setConfiguratorData,
                    setSelectedConfigurator,
                    setPriceData,
                    setColorData,
                    setEngineData,
                    setPriceFromFields,
                    setConditionalLogic,
                    setAppliedConditionalLogic,
                    setActiveField,
                    setFieldsData,
                    setQuotationFolder,
                    setHeaderData,
                    setAllConfigurators,
                    setEditedProduct,
                    setStatusQuotationFolder,
                    setCompleteQuotation,
                    setStartContentData,
                    setPriceUpdating,
                    setConfiguratorHubspotUrl,
                    setSelectedColors,
                    setExtraPriceRules,
                    setEmployeeConfigurator
                },
            ]}
        >
            {children}
        </GlobalContext.Provider>
    );
};

export const useGlobalState = () => {
    const [state, actions] = useContext(GlobalContext);
    return {
        configuratorData: state.configuratorData,
        setConfiguratorData: actions.setConfiguratorData,
        selectedConfigurator: state.selectedConfigurator,
        setSelectedConfigurator: actions.setSelectedConfigurator,
        priceData: state.priceData,
        setPriceData: actions.setPriceData,
        colorData: state.colorData,
        setColorData: actions.setColorData,
        engineData: state.engineData,
        setEngineData: actions.setEngineData,
        priceFromFields: state.priceFromFields,
        setPriceFromFields: actions.setPriceFromFields,
        conditionalLogic: state.conditionalLogic,
        setConditionalLogic: actions.setConditionalLogic,
        appliedConditionalLogic: state.appliedConditionalLogic,
        setAppliedConditionalLogic: actions.setAppliedConditionalLogic,
        activeField: state.activeField,
        setActiveField: actions.setActiveField,
        fieldsData: state.fieldsData,
        setFieldsData: actions.setFieldsData,
        quotationFolder: state.quotationFolder,
        setQuotationFolder: actions.setQuotationFolder,
        headerData: state.headerData,
        setHeaderData: actions.setHeaderData,
        allConfigurators: state.allConfigurators,
        setAllConfigurators: actions.setAllConfigurators,
        editProduct: state.editProduct,
        setEditedProduct: actions.setEditedProduct,
        completeQuotationFolder: state.completeQuotationFolder,
        setStatusQuotationFolder: actions.setStatusQuotationFolder,
        completeQuotation: state.completeQuotation,
        setCompleteQuotation: actions.setCompleteQuotation,
        startContentData: state.startContentData,
        setStartContentData: actions.setStartContentData,
        priceUpdating: state.priceUpdating,
        setPriceUpdating: actions.setPriceUpdating,
        configuratorHubspotUrl: state.configuratorHubspotUrl,
        setConfiguratorHubspotUrl: actions.setConfiguratorHubspotUrl,
        selectedColors: state.selectedColors,
        setSelectedColors: actions.setSelectedColors,
        extraPriceRules: state.extraPriceRules,
        setExtraPriceRules: actions.setExtraPriceRules,
        employeeConfigurator: state.employeeConfigurator,
        setEmployeeConfigurator: actions.setEmployeeConfigurator
    };
};
